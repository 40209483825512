import { useMutation } from "@apollo/client";
import { Input, Select, Text } from "components/Form";
import CharlesButton from "components/charles/base";
import { useState } from "react";
import { Alert } from "components/Toast";
import { RETAILER_CREATE_CUSTOMER } from "../graphql";
import { useNavigate } from "react-router-dom";
import AgentSelector from "../AgentSelector";
import { isTesting } from "../utils";

const RetailerForm = ({ hide }) => {
  const randomNumber = Math.floor(Math.random() * 1000000);
  const [retailer, setRetailer] = useState({
    name: isTesting ? "Retailer Name " + randomNumber : "",
    email: isTesting ? "retailer_" + randomNumber + "@example.com" : "",
    vat: isTesting ? "test" + Math.floor(Math.random() * 100000) : "",
    belongToAgentUserId: null,
    billingAddressName: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    billingAddressStreet1: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    billingAddressStreet2: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    billingAddressCity: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    billingAddressState: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    billingAddressZip: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    billingAddressCountry: "Spain",
    shippingAddressName: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    shippingAddressStreet1: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    shippingAddressStreet2: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    shippingAddressCity: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    shippingAddressState: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    shippingAddressZip: isTesting
      ? "test" + Math.floor(Math.random() * 100000)
      : "",
    shippingAddressCountry: "Spain",
    remark: isTesting ? "test" + Math.floor(Math.random() * 100000) : "",
  });

  const navigate = useNavigate();

  const [createRetailer, createRetailerRes] = useMutation(
    RETAILER_CREATE_CUSTOMER,
    {
      variables: retailer,
      onCompleted: (res) => {
        console.log("res", res);
        Alert("success", "Retailer created successfully.");
        hide();
        navigate(
          `/retailer-store/retailers/${res.retailerCreateCustomer.customer.id}`,
        );
      },
      onError(error) {
        Alert("error", error.message);
      },
      refetchQueries: ["FETCH_RETAILER_CUSTOMERS"],
    },
  );

  return (
    <div className="text-sm">
      <table>
        <tbody>
          <tr>
            <td>
              <label htmlFor="">Name:</label>
            </td>
            <td className="w-full">
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.name}
                onChange={(e) =>
                  setRetailer({ ...retailer, name: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="">Email:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                type="email"
                value={retailer.email}
                onChange={(e) =>
                  setRetailer({ ...retailer, email: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="">VAT:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.vat}
                onChange={(e) =>
                  setRetailer({ ...retailer, vat: e.target.value })
                }
              />
            </td>
          </tr>

          {/* Agent */}
          <tr>
            <td>
              <label htmlFor="">Agent:</label>
            </td>
            <td>
              <AgentSelector
                value={retailer.belongToAgentUserId}
                onChange={(value) =>
                  setRetailer({ ...retailer, belongToAgentUserId: value })
                }
              />
            </td>
          </tr>

          {/* Billing Address */}

          <tr>
            <td colSpan={2}>
              <h5 className="pt-4">Billing Address</h5>
              <div className="opacity-70 text-xs">
                Optional, it auto fill the order form when the retailer confirm
                an order.
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="">Name:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.billingAddressName}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    billingAddressName: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="">Street 1:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.billingAddressStreet1}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    billingAddressStreet1: e.target.value,
                  })
                }
              />
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="">Street 2:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.billingAddressStreet2}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    billingAddressStreet2: e.target.value,
                  })
                }
              />
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="">City:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.billingAddressCity}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    billingAddressCity: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="">State:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.billingAddressState}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    billingAddressState: e.target.value,
                  })
                }
              />
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="">Zip:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.billingAddressZip}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    billingAddressZip: e.target.value,
                  })
                }
              />
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="">Country:</label>
            </td>
            <td>
              <Select
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.billingAddressCountry}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    billingAddressCountry: e.target.value,
                  })
                }
              >
                <option value="Spain">Spain</option>
                <option value="France">France</option>
              </Select>
            </td>
          </tr>

          {/* Shipping Adddress */}

          <tr>
            <td colSpan={2}>
              <h5 className="pt-4">Shipping Address</h5>
              <div className="opacity-70 text-xs">
                Optional, it auto fill the order form when the retailer confirm
                an order.
              </div>
              <CharlesButton
                className=" text-xs"
                onClick={() => {
                  setRetailer({
                    ...retailer,
                    shippingAddressName: retailer.billingAddressName,
                    shippingAddressStreet1: retailer.billingAddressStreet1,
                    shippingAddressStreet2: retailer.billingAddressStreet2,
                    shippingAddressCity: retailer.billingAddressCity,
                    shippingAddressState: retailer.billingAddressState,
                    shippingAddressZip: retailer.billingAddressZip,
                    shippingAddressCountry: retailer.billingAddressCountry,
                  });
                }}
              >
                Copy Billing Address
              </CharlesButton>
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="">Name:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.shippingAddressName}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    shippingAddressName: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="">Street 1:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.shippingAddressStreet1}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    shippingAddressStreet1: e.target.value,
                  })
                }
              />
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="">Street 2:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.shippingAddressStreet2}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    shippingAddressStreet2: e.target.value,
                  })
                }
              />
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="">City:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.shippingAddressCity}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    shippingAddressCity: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="">State:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.shippingAddressState}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    shippingAddressState: e.target.value,
                  })
                }
              />
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="">Zip:</label>
            </td>
            <td>
              <Input
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.shippingAddressZip}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    shippingAddressZip: e.target.value,
                  })
                }
              />
            </td>
          </tr>

          <tr>
            <td>
              <label htmlFor="">Country:</label>
            </td>
            <td>
              <Select
                className="w-full"
                bgColor="dark:bg-gray-900"
                value={retailer.shippingAddressCountry}
                onChange={(e) =>
                  setRetailer({
                    ...retailer,
                    shippingAddressCountry: e.target.value,
                  })
                }
              >
                <option value="Spain">Spain</option>
                <option value="France">France</option>
              </Select>
            </td>
          </tr>

          {/* Preference */}

          <tr>
            <td colSpan={2}>
              <h5 className="pt-4">Preference</h5>
            </td>
          </tr>
          <tr>
            <td className="align-baseline">
              <label htmlFor="">Remark:</label>
            </td>
            <td>
              <Text
                bgColor="dark:bg-gray-900"
                value={retailer.remark}
                onChange={(e) =>
                  setRetailer({ ...retailer, remark: e.target.value })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div className="my-6">
        <CharlesButton
          primary
          onClick={createRetailer}
          loading={createRetailerRes.loading}
        >
          Save
        </CharlesButton>
      </div>
    </div>
  );
};

export default RetailerForm;
