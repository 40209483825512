import { gql } from "@apollo/client";

export const RETAILER_CREATE_CUSTOMER = gql`
  mutation RETAILER_CREATE_CUSTOMER(
    $name: String!
    $email: String!
    $vat: String
    $belongToAgentUserId: ID
    $billingAddressName: String
    $billingAddressStreet1: String
    $billingAddressStreet2: String
    $billingAddressCity: String
    $billingAddressState: String
    $billingAddressZip: String
    $billingAddressCountry: String
    $shippingAddressName: String
    $shippingAddressStreet1: String
    $shippingAddressStreet2: String
    $shippingAddressCity: String
    $shippingAddressState: String
    $shippingAddressZip: String
    $shippingAddressCountry: String
    $remark: String
  ) {
    retailerCreateCustomer(
      name: $name
      email: $email
      vat: $vat
      belongToAgentUserId: $belongToAgentUserId
      billingAddressName: $billingAddressName
      billingAddressStreet1: $billingAddressStreet1
      billingAddressStreet2: $billingAddressStreet2
      billingAddressCity: $billingAddressCity
      billingAddressState: $billingAddressState
      billingAddressZip: $billingAddressZip
      billingAddressCountry: $billingAddressCountry
      shippingAddressName: $shippingAddressName
      shippingAddressStreet1: $shippingAddressStreet1
      shippingAddressStreet2: $shippingAddressStreet2
      shippingAddressCity: $shippingAddressCity
      shippingAddressState: $shippingAddressState
      shippingAddressZip: $shippingAddressZip
      shippingAddressCountry: $shippingAddressCountry
      remark: $remark
    ) {
      customer {
        id
        name
        email
        vat
        belongToAgentUser {
          id
          email
        }
        billingAddressName
        billingAddressStreet1
        billingAddressStreet2
        billingAddressCity
        billingAddressState
        billingAddressZip
        billingAddressCountry
        shippingAddressName
        shippingAddressStreet1
        shippingAddressStreet2
        shippingAddressCity
        shippingAddressState
        shippingAddressZip
        shippingAddressCountry
        remark
      }
    }
  }
`;

export const FETCH_RETAILER_AGENTS = gql`
  query FETCH_RETAILER_AGENTS {
    retailerAgents {
      id
      email
      agentCustomers {
        id
      }
    }
  }
`;

export const RETAILER_CREATE_AGENT = gql`
  mutation RETAILER_CREATE_AGENT(
    $email: String!
    $firstName: String!
    $lastName: String
    $password: String!
  ) {
    retailerCreateAgent(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
    ) {
      user {
        id
      }
    }
  }
`;
