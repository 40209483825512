import { useMutation, useQuery } from "@apollo/client";
import Page from "components/Page";
import { useParams } from "react-router-dom";
import { FETCH_PRODUCT, RETAILER_SAVE_PRODUCT } from "./graphlq";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { useEffect, useState } from "react";
import CharlesButton from "components/charles/base";
import { Input, NativeDatePicker, Text } from "components/Form";
import ProductMappingView from "./ProductMappingView";
import ProductImagesView from "./ProductImagesView";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import moment from "moment";

const ProductDetail = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCT, {
    variables: { id },
  });

  const [product, setProduct] = useState(null);
  const [save, saveRes] = useMutation(RETAILER_SAVE_PRODUCT, {
    onCompleted() {
      Alert("success", "Product saved.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  function saveHandler() {
    save({
      variables: {
        id,
        productInput: {
          name: product.name,
          description: product.description,
          price: product.price,
          retailValue: product.retailValue || 0,
          recommendedRetailPrice: product.recommendedRetailPrice || 0,
          isActive: product.isActive,
          inStock: product.inStock,
          backToStockAt: product.backToStockAt,
        },
        bom: product.bom.map((b) => ({
          wisProductId: b.wisProduct.id,
          qty: b.qty,
          totalPrice: b.totalPrice,
        })),
      },
    });
  }

  useEffect(() => {
    if (data) {
      setProduct({
        ...data.retailerStoreProduct,
        backToStockAt: moment(data.retailerStoreProduct.backToStockAt).format(
          "YYYY-MM-DD",
        ),
      });
    }
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  if (!product) return null;

  return (
    <Page
      title={product.name}
      backTo="./.."
      rightButtons={
        <div>
          <CharlesButton onClick={saveHandler} loading={saveRes.loading}>
            Save
          </CharlesButton>
        </div>
      }
    >
      <div className="p-6 grid grid-cols-12 gap-6 text-sm">
        <div className="col-span-8 space-y-6">
          <div className="card px-6 py-4">
            <h4>Basic Info</h4>
            <div className="-mx-2">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor="">Name: </label>
                    </td>
                    <td className="w-full">
                      <Input
                        value={product.name}
                        onChange={(e) =>
                          setProduct({ ...product, name: e.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className=" align-top">
                      <label htmlFor="">Description: </label>
                    </td>
                    <td>
                      <Text
                        value={product.description}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            description: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label htmlFor="">Unit Price:</label>
                    </td>
                    <td>
                      <Input
                        value={product.price}
                        onChange={(e) =>
                          setProduct({ ...product, price: e.target.value })
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label htmlFor="">Retail Value:</label>
                    </td>
                    <td>
                      <Input
                        value={product.retailValue}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            retailValue: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label htmlFor="">Recommended Retail Price:</label>
                    </td>
                    <td>
                      <Input
                        value={product.recommendedRetailPrice}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            recommendedRetailPrice: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <ProductMappingView product={product} setProduct={setProduct} />
        </div>

        <div className="col-span-4 space-y-6">
          <div className="card px-6 py-4">
            <h4>Status</h4>
            <div className="mt-4 -mx-2">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor="">Available: </label>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={product.isActive}
                        onChange={(e) =>
                          setProduct({ ...product, isActive: e.target.checked })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="">In Stock</label>
                    </td>
                    <td className="w-full">
                      <input
                        type="checkbox"
                        checked={product.inStock}
                        onChange={(e) =>
                          setProduct({ ...product, inStock: e.target.checked })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="">Back In Stock</label>
                    </td>
                    <td className="w-full">
                      <NativeDatePicker
                        value={product.backToStockAt}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            backToStockAt: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <ProductImagesView product={product} setProduct={setProduct} />
        </div>
      </div>
    </Page>
  );
};

export default ProductDetail;
