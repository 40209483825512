import { useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Page from "components/Page";
import Spinner from "components/Spinner";
import { useParams } from "react-router-dom";
import { FETCH_RETAILER_ORDER } from "./graphql";
import CharlesButton from "components/charles/base";
import Status, { getOrderStateColor } from "./Status";
import { useModals } from "ModalProvider";
import ConfirmOfflinePaymentView from "./ConfirmOfflinePaymentView";
import { RetailerOrderStatusChain } from "components/Status";
import { useContext } from "react";
import { OdooContext } from "OdooProvider";
import { RiExternalLinkFill } from "react-icons/ri";

const OrderDetailPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_RETAILER_ORDER, {
    variables: { id },
  });
  const modal = useModals();
  const { odooUrl } = useContext(OdooContext);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const order = data.retailerOrder;

  function confirmPaymentHandler() {
    modal.present({
      title: "Confirm Payment",
      center: true,
      isBeingPresented: true,
      children: <ConfirmOfflinePaymentView hide={modal.hide} orderId={id} />,
    });
  }

  return (
    <Page title={order.id} backTo="./..">
      <div className="p-6 grid grid-cols-12 gap-6 text-sm">
        <div className="col-span-12">
          <RetailerOrderStatusChain status={order.state} />
        </div>

        <div className="col-span-8 space-y-8">
          <div className="card px-8 py-6">
            <h4>Order Items</h4>

            <div className="-mx-2 mt-4">
              <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th className="text-right">Quantity</th>
                    <th className="text-right">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {order.items.map((item) => (
                    <tr
                      key={item.id}
                      className=" border-y border-gray-100 dark:border-gray-700"
                    >
                      <td>
                        <div className="font-bold">{item.product.name}</div>
                      </td>
                      <td className="text-right">{item.qty}</td>
                      <td className="text-right">{item.subtotal}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total</td>
                    <td className="text-right">
                      {order.items.reduce((acc, item) => acc + item.qty, 0)}
                    </td>
                    <td className="text-right">{order.total}</td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <hr />

            <div className="-mx-2">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h5>Invoice Address</h5>
                      <div>{order.billingAddressName}</div>
                      <div>{order.billingAddress}</div>
                    </td>

                    <td>
                      <h5>Shipping Address</h5>
                      <div>{order.shippingAddressName}</div>
                      <div>{order.shippingAddress}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {order.remark && (
              <div>
                <hr />
                <h4>Remark</h4>
                <div>{order.remark}</div>
              </div>
            )}
          </div>
        </div>

        <div className=" col-span-4 space-y-8">
          <div className="card">
            <h4>Actions</h4>
            <div className="mt-2">
              {order.state === "VALIDATING_PAYMENT" && (
                <div className="mt-4">
                  <div>
                    This order is waiting for payment confirmation. If this is
                    an offline payment, please confirm the payment manually.
                    Once you have received the payment, please confirm the
                    payment below.
                  </div>
                  <CharlesButton
                    className="mt-4"
                    onClick={confirmPaymentHandler}
                    primary
                  >
                    Confirm Payment
                  </CharlesButton>
                </div>
              )}
            </div>
          </div>

          <div className="card">
            <h4>Odoo</h4>
            <div className="mt-2 space-y-2">
              {order.odooId && (
                <div>
                  <a
                    target="_blank"
                    className="text-purple-600 flex items-center space-x-1"
                    rel="noreferrer"
                    href={`${odooUrl}/web#id=${order.odooId}&cids=1&menu_id=408&action=312&model=sale.order&view_type=form`}
                  >
                    <span>{order.odooName}</span>
                    <span>
                      <RiExternalLinkFill />
                    </span>
                  </a>
                </div>
              )}

              <CharlesButton>Download Odoo Invoice</CharlesButton>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default OrderDetailPage;
