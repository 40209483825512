import { gql } from "@apollo/client";

export const FETCH_RETAILER_STORE_PRODUCTS = gql`
  query FETCH_RETAILER_STORE_PRODUCTS {
    retailerStoreProducts {
      id
      name
      isActive
      inStock
      backToStockAt
      price
      qtyPerCarton
      totalQty
      image
      bom {
        id
        wisProduct {
          id
          name
          number
        }
        qty
      }
    }
  }
`;

export const FETCH_PRODUCT = gql`
  query FETCH_PRODUCT($id: ID!) {
    retailerStoreProduct(id: $id) {
      id
      name
      description
      price
      uom
      qtyPerCarton
      retailValue
      recommendedRetailPrice
      isActive
      inStock
      backToStockAt
      images

      bom {
        id
        wisProduct {
          id
          name
          number
        }
        qty
        totalPrice
      }
    }
  }
`;

export const FETCH_WIS_PRODUCTS_FOR_RETAILER_STORE = gql`
  query FETCH_WIS_PRODUCTS_FOR_RETAILER_STORE {
    products(hasOdooId: true, isSalable: true, isActive: true) {
      total
      results {
        id
        name
        number
        odooId
      }
    }
  }
`;

export const RETAILER_SAVE_PRODUCT = gql`
  mutation RETAILER_SAVE_PRODUCT(
    $id: ID!
    $productInput: RetailerProductInput!
    $bom: [RetailerProductChildInput!]
  ) {
    retailerSaveProduct(id: $id, productInput: $productInput, bom: $bom) {
      product {
        id
        name
        description
        price
        qtyPerCarton
        retailValue
        recommendedRetailPrice
        isActive
        inStock
        backToStockAt
        images
        bom {
          id
          wisProduct {
            id
            name
            number
          }
          qty
          totalPrice
        }
      }
    }
  }
`;
