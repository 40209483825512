import CharlesButton from "components/charles/base";
import { FileSelector } from "components/Form";
import { useState } from "react";

const ProductImagesView = ({ product, setProduct }) => {
  const [images, setImages] = useState(product.images);

  return (
    <div className="card px-6 py-4">
      <h4>Images</h4>
      <div className="mt-4">
        {images.map((image, index) => (
          <div key={index} className="flex items-center space-x-4">
            <img src={image} alt="" className="w-full object-cover" />
          </div>
        ))}
      </div>

      <div className="mt-4 space-x-4 flex">
        <FileSelector title="Choose Images" />
        <CharlesButton>Choose Images from OneDrive</CharlesButton>
      </div>
    </div>
  );
};

export default ProductImagesView;
