import { AiFillAmazonCircle, AiFillRobot } from "react-icons/ai";
import {
  RiBarcodeFill,
  RiListSettingsFill,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";
import {
  BsCalendar3,
  BsCalendar4Week,
  BsFillPatchCheckFill,
  BsImages,
  BsTruck,
} from "react-icons/bs";
import { FaFileInvoiceDollar, FaStore, FaTruck } from "react-icons/fa";
import { SiProducthunt, SiShopify } from "react-icons/si";
import { IoGitMerge } from "react-icons/io5";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { FiCircle } from "react-icons/fi";
import { GoShieldCheck } from "react-icons/go";
import { PiPackageBold } from "react-icons/pi";
import { INTERNAL, QC } from "utils/permissions";

const ROUTES = {
  products: [
    {
      path: "/products",
      text: "Products",
      icon: "ball",
      color: "text-pink-400",
    },
    {
      path: "/product-list",
      text: "Product List",
      icon: "view-list",
      color: "text-red-400",
    },
    {
      path: "/calculate",
      text: "Calculate",
      icon: "calculator",
      color: "text-blue-400",
    },
    { path: "/stock", text: "Stock", icon: "stock", color: "text-green-400" },
    {
      path: "/price",
      text: "Costs",
      icon: <RiMoneyDollarCircleLine size={14} />,
      color: "text-yellow-400",
    },
    {
      path: "/test-reports",
      text: "Test Reports",
      icon: <GoShieldCheck size={14} />,
      color: "text-teal-400",
    },
    {
      path: "/manage",
      text: "Manage",
      icon: <RiListSettingsFill size={14} />,
      color: "text-blue-400",
    },
    {
      path: "/validate",
      text: "Validate",
      icon: <BsFillPatchCheckFill size={14} />,
      color: "text-green-400",
    },
  ],
  orders: [
    {
      path: "/customers",
      text: "Customers",
      icon: "user",
      color: "text-green-400",
    },
    {
      path: "/shipment",
      text: "Shipments",
      icon: "ship",
      color: "text-pink-400",
    },
    {
      path: "/odoo",
      text: "Odoo",
      icon: <FiCircle size={14} />,
      color: "text-purple-400",
    },
    {
      path: "/amazon",
      text: "Amazon Vendor Center",
      icon: <AiFillAmazonCircle size={14} />,
      color: "text-orange-400",
    },
    {
      path: "/orders",
      text: "Sales Orders",
      icon: <HiOutlineShoppingBag size={14} />,
      color: "text-red-400",
    },
    {
      path: "/alc",
      text: "ALC",
      icon: <BsTruck size={14} />,
      color: "text-indigo-400",
    },
    {
      path: "/fsi",
      text: "FSI",
      icon: <FaTruck size={14} />,
      color: "text-indigo-400",
    },
    {
      path: "/inc",
      text: "INC",
      icon: <FaStore size={14} />,
      color: "text-blue-400",
    },
  ],
  supplyChain: [
    {
      path: "/order-scheduler",
      text: "Order Scheduler",
      icon: <BsCalendar3 size={14} />,
      color: "text-pink-400",
    },
    {
      path: "/supplier-calendar",
      text: "Production Calendar",
      icon: <BsCalendar4Week size={14} />,
      color: "text-red-400",
    },
    {
      path: "/production",
      text: "Production",
      icon: <SiProducthunt size={14} />,
      color: "text-teal-400",
    },
    {
      path: "/sub-supplier",
      text: "Sub-Supplier",
      icon: <IoGitMerge size={14} />,
      color: "text-blue-400",
    },
  ],
  qc: [{ path: "/qc", text: "Reports", icon: "paste", color: "text-pink-400" }],
  esg: [
    { path: "/csr", text: "CSR", icon: "factory", color: "text-green-400" },
    {
      path: "/assessment",
      text: "Assessment",
      icon: "factory",
      color: "text-blue-400",
    },
  ],
  retailer: [
    {
      path: "/retailer-store/products",
      text: "Products",
      icon: "ball",
      color: "text-blue-400",
    },
    {
      path: "/retailer-store/agents",
      text: "Agents",
      icon: "user",
      color: "text-blue-400",
    },
    {
      path: "/retailer-store/retailers",
      text: "Retailers",
      icon: "user",
      color: "text-green-400",
    },
    {
      path: "/retailer-store/orders",
      text: "Orders",
      icon: <FaFileInvoiceDollar size={14} />,
      color: "text-purple-400",
    },
  ],
  materials: [
    {
      path: "/materials/beautyshots",
      text: "Beautyshots",
      icon: <BsImages />,
      color: "text-pink-400",
    },
    {
      path: "/materials/artwork-copy",
      text: "Artwork Copy",
      icon: "format-text-size",
      color: "text-blue-400",
    },
    {
      path: "/materials/lifestyle-images",
      text: "Lifestyle Images",
      icon: "beverage",
      color: "text-green-400",
    },
    {
      path: "/materials/logos",
      text: "Logos",
      icon: "color-palette",
      color: "text-green-400",
    },
    {
      path: "/materials/videos",
      text: "Videos",
      icon: "video",
      color: "text-yellow-400",
    },
  ],
  tools: [
    {
      path: "/tools/shortlinks",
      text: "Short Links",
      icon: "link",
      color: "text-yellow-400",
    },
    {
      path: "/barcode",
      text: "Barcode",
      icon: <RiBarcodeFill size={14} />,
      color: "text-indigo-400",
      target: "_blank",
    },
    {
      path: "/tools/shopify",
      text: "Shopify",
      icon: <SiShopify size={14} />,
      color: "text-blue-400",
    },
    {
      path: "/tools/patentai",
      text: "Patent AI",
      icon: <AiFillRobot size={14} />,
      color: "text-green-400",
    },
    {
      path: "/tools/package",
      text: "Package Tracking",
      icon: <PiPackageBold size={14} />,
      color: "text-orange-400",
    },
  ],
  analytics: [
    {
      path: "/analytics/sales",
      text: "Sales",
      icon: "chart",
      color: "text-indigo-400",
    },
    {
      path: "/analytics/qc",
      text: "QC",
      icon: "chart",
      color: "text-teal-400",
    },
    {
      path: "/analytics/production",
      text: "Production",
      icon: "chart",
      color: "text-teal-400",
    },
    {
      path: "/analytics/grading",
      text: "Supplier Grading",
      icon: "chart",
      color: "text-green-400",
    },
    {
      path: "/analytics/odoo",
      text: "Odoo Margins",
      icon: "chart",
      color: "text-purple-400",
    },
  ],
  manage: [
    { path: "/manage/accounts", text: "Accounts", icon: "user" },
    {
      blank: true,
      path: process.env.REACT_APP_SERVER_ADMIN_URL,
      text: "Admin",
      icon: "shield",
      color: "text-green-400",
    },
    { path: "/it", text: "IT", icon: "code", color: "text-pink-400" },
  ],
};

export const getUserRoutes = (hasPermission) => {
  const routes = [];

  if (hasPermission(INTERNAL)) {
    routes.push({ text: "Products", path: ROUTES.products });
    routes.push({ text: "Orders", path: ROUTES.orders });
    routes.push({ text: "Supply Chain", path: ROUTES.supplyChain });
    routes.push({ text: "QC", path: ROUTES.qc });
    routes.push({ text: "ESG", path: ROUTES.esg });
    routes.push({ text: "Materials", path: ROUTES.materials });
    routes.push({ text: "Tools", path: ROUTES.tools });
    routes.push({ text: "Analytics", path: ROUTES.analytics });
    routes.push({ text: "Manage", path: ROUTES.manage });
    routes.push({ text: "Retailer Store", path: ROUTES.retailer });
  } else if (hasPermission(QC)) {
    routes.push({ text: "QC", path: ROUTES.qc });
  }
  return routes;
};
