import { useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { CONFIRM_OFFLINE_PAYMENT } from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";

const ConfirmOfflinePaymentView = ({ orderId, hide }) => {
  const [confirm, confirmRes] = useMutation(CONFIRM_OFFLINE_PAYMENT, {
    variables: { orderId },
    onCompleted() {
      Alert("success", "Payment confirmed successfully");
      hide();
    },
    onError(err) {
      Alert("error", parseError(err));
    },
  });

  return (
    <div>
      <div>
        <p>Are you sure you want to confirm this payment?</p>
        <p>Once you confirm this payment, the order will be marked as paid.</p>
      </div>

      <hr />

      <div>
        <CharlesButton primary onClick={confirm} loading={confirmRes.loading}>
          Confirm Payment
        </CharlesButton>
      </div>
    </div>
  );
};

export default ConfirmOfflinePaymentView;
