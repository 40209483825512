import { useQuery } from "@apollo/client";
import { FETCH_WIS_PRODUCTS_FOR_RETAILER_STORE } from "./graphlq";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import { useState } from "react";
import { searchByProp } from "utils/search";

const ProductSelector = ({ onSelect, hide }) => {
  const [searchText, setSearchText] = useState("");
  const { loading, error, data } = useQuery(
    FETCH_WIS_PRODUCTS_FOR_RETAILER_STORE,
  );

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const products = data.products.results
    .filter((product) =>
      searchByProp(product, ["name", "number", "odooId"], searchText),
    )
    .sort((a, b) => a.number.replace(/\D/g, "") - b.number.replace(/\D/g, ""));

  return (
    <div>
      <div>
        <SearchBar
          value={searchText}
          onChange={setSearchText}
          placeholder="Search product by name or number."
        />
      </div>
      <div className="mt-2 text-sm whitespace-nowrap">
        <table>
          <tbody>
            {products.map((product, index) => (
              <tr
                key={index}
                className="cursor-pointer border-y dark:border-gray-700 hover:bg-blue-100"
                onClick={() => onSelect(product)}
              >
                <td>
                  #{product.odooId}: {product.number}
                </td>
                <td className="w-full">{product.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductSelector;
