import { Fragment } from "react";
import { BsArrowRight } from "react-icons/bs";

const ORDER_STATUS = [
  "AWAITING_PAYMENT",
  "VALIDATING_PAYMENT",
  "PAID",
  "SHIPPING",
  "DELIVERED",
];

const Status = ({ state }) => (
  <div className="font-bold capitalize">
    {state.replace("_", " ").toLowerCase()}
  </div>
);

export function getOrderStateColor(state) {
  if (state === "PAID") return "text-green-600";
  if (state === "AWAITING_PAYMENT") return "text-sky-600";
  if (state === "VALIDATING_PAYMENT") return "text-orange-600";
  return "";
}

export const OrderStatusChain = ({ state }) => {
  const status = state;
  if (status === "CANCELED") return <Status status="CANCELED" />;
  return (
    <div className="flex items-center space-x-3">
      {ORDER_STATUS.map((i, index) => (
        <Fragment key={index}>
          {index === 0 ? null : (
            <BsArrowRight
              className={
                ORDER_STATUS.indexOf(status) >= index ? "" : " opacity-30"
              }
            />
          )}
          <Status state={i} active={ORDER_STATUS.indexOf(status) >= index} />
        </Fragment>
      ))}
    </div>
  );
};

export default Status;
