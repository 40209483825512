import { Navigate, Route, Routes } from "react-router-dom";
import RetailerPages from "./retailers";
import AgentPages from "./agents";
import OrderPages from "./orders";
import PriceListPage from "./PriceList";
import ProductPages from "./product";

const RetailerStorePages = () => {
  return (
    <Routes>
      <Route path="products/*" element={<ProductPages />} />
      <Route path="retailers/*" element={<RetailerPages />} />
      <Route path="agents/*" element={<AgentPages />} />
      <Route path="orders/*" element={<OrderPages />} />
      <Route path="price-list" element={<PriceListPage />} />
      <Route path="*" element={<Navigate to="/retailer-store/retailers" />} />
    </Routes>
  );
};

export default RetailerStorePages;
